.homeGridHover {
  transform: scale(1);
  transition: all 0.3s linear;
}

.homeGridHover:hover {
  transform: scale(1.05) rotate(1deg);
  filter: drop-shadow(0px 0px 12px rgba(216, 216, 216, 0.555));
  width: 300px;
}

.homeGridHover:active {
  transform: scale(0.98) rotate(1deg);
  filter: drop-shadow(0px 0px 12px rgba(216, 216, 216, 0.555));
  width: 300px;
}
